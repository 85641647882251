'use client';

import React, { useEffect, useCallback } from 'react';
import dayjs from '@/lib/dayjs/dayjs';

import usePollingHook from '../../../../hooks/usePollinghook';
import { useConfirmationContext } from '../../../../data/context/confirmationContext';
import auth, { SESSION_FLASH_MESSAGE_KEY, SESSION_TIMEOUT_MESSAGE } from '../../../../modules/auth/auth';
import setFlashMessage from '../../../FlashMessage/helpers/setFlashMessage';

import Countdown from '../../../_ui/Countdown';
import Typography from '../../../_ui/_blocks/Typography/Typography';

// Mins to be inactive before showing message.
const timeoutMins = 10;
const timeoutSeconds = timeoutMins * 60;
let lastActive = dayjs();

/**
 * Monitors user clicks and displays a message if the user hasn't interacted with the page.
 */
const useSessionActivity = (isLoggedIn: boolean, logout: () => Promise<void>) => {


    const { openConfirmation, closeConfirmation, isConfirmationOpen } = useConfirmationContext();

    // Wrapped the logout in a callback. It should be in a callback in the auth context logic but I don't want
    // to mess with the context logic, it may cause side effects.
    const logoutCallBack = useCallback(() => (
        logout()
    ), []); // eslint-disable-line react-hooks/exhaustive-deps

    const forceLogout = useCallback(async () => {
        lastActive = dayjs();
        auth.storeRedirectForAfterLogin(window.location.href);
        dispatchEvent(new Event('force_logout'));
        await logoutCallBack();
        closeConfirmation();
        setFlashMessage(SESSION_TIMEOUT_MESSAGE, SESSION_FLASH_MESSAGE_KEY);
    }, [closeConfirmation, logoutCallBack]);


    /**
     * Opens the confirmation window.
     */
    const displayEndSessionMessage = useCallback(() => {
        const expireTime = dayjs().add(1, 'minute').format('YYYY-MM-DD HH:mm:ss');

        /*
            * Sets the last active to current time and requests new tokens.
        */
        const continueSession = () => {
            lastActive = dayjs();
            auth.refreshToken();
            closeConfirmation();
        };
        
        openConfirmation({
            type: 'alert',
            severity: 'warning',
            heading: 'Session is about to expire',
            children: (
                <div className="space-y-050">
                    <Typography size="090" as="p" color="quiet">
                        Your browsing session is about to expire and you will be logged out of the application.
                    </Typography>
                    <Typography as="p">
                        <span>Your session will expire in: </span>
                        <Countdown countDownTo={expireTime} onExpire={forceLogout}>
                            {({ seconds }) => (
                                <strong>{seconds} {seconds === 1 ? 'second' : 'seconds'}</strong>
                            )}
                        </Countdown>
                    </Typography>
                </div>
            ),
            onClose: continueSession,
            controls: {
                secondary: {
                    text: 'Log out',
                    title: 'Log out',
                    onClick: logout,
                },
                primary: {
                    text: 'Continue',
                    title: 'Continue',
                    onClick: continueSession,
                },
            },
        });
    }, [forceLogout, logout, openConfirmation, closeConfirmation]);

    // Checks if the user has been inactive for 10 minutes.
    const pollLastActive = useCallback(() => {
        if (isConfirmationOpen) {
            return;
        }

        const now = dayjs();
        
        // If it has been timeout period since a click, display the confirmation message.
        if (
            now.diff(lastActive, 'second') >= timeoutSeconds 
            && document.visibilityState === 'visible') {
            displayEndSessionMessage();
        }
    }, [isConfirmationOpen, displayEndSessionMessage]); 

    // Polls the pollLastActive function if user is logged in.
    usePollingHook(isLoggedIn ? pollLastActive : null, 5000, [isLoggedIn, pollLastActive], false);

    // Adds and removes click event listeners if the user is logged in.
    useEffect(() => {
        if (!isLoggedIn) {
            return () => {};
        }

        const updateLastActive = () => {
            lastActive = dayjs();
        };

        // If logged in is changed and we are logged in then we want to update the last active to now.
        updateLastActive();

        const visibilityChange = () => {
            if (document.visibilityState === 'visible') {
                updateLastActive();
            }
        };

        document.body.addEventListener('click', updateLastActive);

        // For when people load into the tab if they've been working with multiple tabs.
        document.addEventListener(
            'visibilitychange',
            visibilityChange
        );

        return () => {
            document.body.removeEventListener('click', updateLastActive);
            window.removeEventListener('visibilitychange', visibilityChange);
        };
    }, [isLoggedIn]);
};

export default useSessionActivity;
